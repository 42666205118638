import {
    Autocomplete,
    autocompleteClasses,
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Popper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import XeroLogo from "../../../assets/xero.svg";

import SyncIcon from "@mui/icons-material/Sync";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIcon from "@mui/icons-material/Edit";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IcomeTableDet from "./IcomeTableDet";
import SimpleBackdrop from "../../ui/SimpleBackdrop";

import conversionService from "../../../services/conversion";
import LightTooltip from "../../ui/LightTooltip";
import { formatAmount, formatDate, getContactIcon, similarity } from "../../../utils/utils";

import reportService from "../../../services/reports";
import IncomeHistSync from "./IncomeHistSync";
import FormDialog from "../../ui/FormDialog";
import EditContactChecks from "../checks/EditContactChecks";

import moment from "moment";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
    },
    "& p": {
        color: "#131F3E",
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: "15px",
    },
});

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const IncomePrev = (props) => {
    const { setPreviewInc, setIsReload, conversionData, setOpenPreview, integration, handleClickHelp, setFileData } =
        props;
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [contact, setContact] = useState({});
    const [reference, setReference] = useState(
        conversionData.description_xero ??
            `${t("incomes.amazonReport")} - ${moment
                .utc(conversionData.formatted_end_date)
                .locale(t("language.locale"))
                .format("MMM DD, YYYY")}`
    );
    const [accountsXeroInc, setAccountsXeroInc] = useState([]);
    const [accountsXeroExp, setAccountsXeroExp] = useState([]);
    const [contactsXero, setContactsXero] = useState([]);
    const [contactsXeroOriginal, setContactsXeroOriginal] = useState([]);
    const [xeroTaxRates, setXeroTaxRates] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsOG, setRowsOG] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showHistSync, setShowHistSync] = useState(false);
    const [rate, setRate] = useState(conversionData.exchange_rate ?? "");
    const [contactXeroUpdated, setContactXeroUpdated] = useState("");
    const [extContactName, setExtContactName] = useState("");
    const [openModalEditContact, setOpenModalEditContact] = useState(false);
    const [allCurrencies, setAllCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(
        conversionData?.updated_currency ? conversionData.updated_currency : conversionData.formatted_currency ?? ""
    );
    const [currencyChange, setCurrencyChange] = useState(false);

    const getCon =
        conversionData.contact_name_xero ??
        (conversionData.conversion_type === 1 ? conversionData.customer_name : conversionData.vendor_name);

    const handleChangeRate = (event) => {
        setRate(event.target.value);
    };

    const handleCloseDialog = (event) => {
        event.preventDefault();
        setOpenModalEditContact(false);
        setContactXeroUpdated("");
    };

    useEffect(() => {
        if (contactsXeroOriginal.length > 0) {
            let newContacts = [];
            const findContactAmazon = contactsXeroOriginal.find((item) => item.name === "Amazon Seller");

            if (getCon) {
                const selectedContactNew = {
                    value: "first",
                    name: getCon,
                };
                const findContactSim = contactsXeroOriginal.find((item) => item.name === getCon);

                if (findContactSim) {
                    newContacts = [...contactsXeroOriginal];
                    selectedContactNew.value = findContactSim.value;
                } else {
                    newContacts = [
                        {
                            value: "first",
                            name: getCon,
                        },
                        ...contactsXeroOriginal,
                    ];
                    // Si no se consigue el contacto exacto, se busca una coincidencia aproximada
                    const foundSimilar = contactsXeroOriginal.find((item) => similarity(item.name, getCon) >= 0.85);
                    if (foundSimilar) {
                        selectedContactNew.value = foundSimilar.value;
                        selectedContactNew.name = foundSimilar.name;
                    }
                }
                setContactsXero(newContacts);
                setContact(selectedContactNew);
            } else if (findContactAmazon) {
                setContact(findContactAmazon);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsXeroOriginal, getCon]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            // Se verifica primero si ya hay una moneda updateada por el usuario
            await conversionService
                .getXeroCurrencies({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    if (data.length > 0) {
                        let currencyMap = data.map((item) => ({
                            id: item.Code,
                            name: item.Description,
                            setup: item.setup,
                        }));

                        setAllCurrencies(currencyMap);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getChartOfAccounts({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    setAccountsXeroInc(
                        data
                            .filter((val) => val.Type === "REVENUE" || val.Type === "SALES")
                            .map((item) => ({ value: item.Code, name: item.Name }))
                    );
                    setAccountsXeroExp(
                        data
                            .filter((val) => val.Type === "EXPENSE" || val.Type === "DIRECTCOSTS")
                            .map((item) => ({ value: item.Code, name: item.Name }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getContacts({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    setContactsXero(
                        data.map((item) => ({
                            value: item.ContactID,
                            name: item.Name,
                            contact_tax_number: item.contact_tax_number,
                        }))
                    );
                    setContactsXeroOriginal(
                        data.map((item) => ({
                            value: item.ContactID,
                            name: item.Name,
                            contact_tax_number: item.contact_tax_number,
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getTaxRates({
                    company_id: todos.companyID,
                    type: 1,
                })
                .then((data) => {
                    if (data) {
                        setXeroTaxRates(
                            data.map((item) => ({
                                value: item.TaxType,
                                name: `${item.Name} - ${item.EffectiveRate}%`,
                                taxRate: item.EffectiveRate,
                                EffectiveRate: item.EffectiveRate,
                                isZeroTax: item.TaxComponents[0].Name === "No Tax",
                            }))
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            const sameCurrency = selectedCurrency === conversionData.account_id?.currency_id_xero;

            const newRows = conversionData.line_items
                .filter(
                    (item) =>
                        (item.formatted_credit || item.formatted_debit || item.unit_price_converter) && !item.deleted
                )
                .map((item) => ({
                    _id: item._id,
                    description: item.description_xero ?? item.description,
                    line_type: item.line_type,
                    formatted_quantity: item.quantity ?? item.formatted_quantity,
                    unit_price_converter: item.unit_price_converter,
                    unit_price_xero: item.unit_price_xero,
                    unit_price:
                        item.unit_price_converter ??
                        (item.unit_price_xero && sameCurrency
                            ? item.unit_price_xero
                            : item.formatted_credit ?? item.formatted_debit),
                    account_code: item.account_code_xero ?? item.account_code ?? "",
                    tax_type: item.tax_type_xero ?? item.tax_type ?? "",
                }));

            setRows(newRows);
            setRowsOG(newRows);

            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversionData, todos.companyID]);

    useEffect(() => {
        (async () => {
            if (selectedCurrency !== "") {
                const sameCurrency = selectedCurrency === conversionData.account_id?.currency_id_xero;

                if (currencyChange) {
                    const newRows = rowsOG.map((item) => ({
                        ...item,
                        unit_price:
                            item.unit_price_converter ??
                            (item.unit_price_xero && sameCurrency ? item.unit_price_xero : item.unit_price),
                    }));

                    setRows(newRows);
                    setRowsOG(newRows);
                }

                if (!sameCurrency) {
                    await conversionService
                        .getXECurrencyRate({
                            from: selectedCurrency,
                            to: conversionData.account_id?.currency_id_xero,
                            date: moment.utc(conversionData.formatted_end_date).format("YYYY-MM-DD"),
                        })
                        .then((data) => {
                            const newRate = data.amount.toString();
                            setRate(newRate.includes(".") ? parseFloat(newRate).toFixed(4) : newRate);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrency]);

    const handleChangeContact = (newValue) => {
        setContact(newValue);
    };

    const handleChangeRef = (e) => {
        setReference(e.target.value);
    };

    const handleGoBack = () => {
        setPreviewInc(false);
        setShowHistSync(false);
        setIsReload((prev) => !prev);
        setFileData(null);
    };

    const handleOpenPrev = () => {
        setOpenPreview(true);
    };

    const getTotalIncome = () => {
        return rowsOG
            .filter((item) => item.line_type === 1)
            .reduce((sum, obj) => sum + obj.unit_price * obj.formatted_quantity, 0);
    };

    const getTotalExpenses = () => {
        return rowsOG
            .filter((item) => item.line_type === 2)
            .reduce((sum, obj) => sum + obj.unit_price * obj.formatted_quantity, 0);
    };

    const getNetIncome = () => {
        return getTotalIncome() + getTotalExpenses();
    };

    const handleDownload = async () => {
        setIsLoading(true);
        const params = {
            report_id: conversionData._id,
            line_items: rowsOG.map((item) => {
                const finalAccounts = item.line_type === 1 ? accountsXeroInc : accountsXeroExp;

                return {
                    description: item.description,
                    line_type: item.line_type,
                    quantity: item.formatted_quantity,
                    unit_price: item.unit_price,
                    account_name: finalAccounts.find((aux) => aux.value === item.account_code).name,
                    tax_name: xeroTaxRates.find((aux) => aux.value === item.tax_type).name,
                    amount: item.formatted_quantity * item.unit_price,
                };
            }),
        };

        await reportService.downloadSyncReport(params).then((response) => {
            // Crea un enlace temporal y haz clic en él para iniciar la descarga

            const link = document.createElement("a");
            link.href = response.url;

            document.body.appendChild(link);
            link.click();

            // Limpia el enlace temporal
            document.body.removeChild(link);
        });

        setIsLoading(false);
    };

    const handleClickEditContact = (event, name) => {
        event.stopPropagation();
        setContactXeroUpdated(name);
        setExtContactName(
            conversionData.conversion_type === 1 ? conversionData.customer_name : conversionData.vendor_name
        );
        setOpenModalEditContact(true);
    };

    const handleSync = async () => {
        setIsLoading(true);

        const newRate = rate === "" ? "1" : rate;
        let type = "RECEIVE";

        if (getNetIncome() < 0) {
            type = "SPEND";
        }

        const params = {
            report_id: conversionData._id,
            contact_id: contact.value === "first" ? "" : contact.value,
            contact_name: contact.name,
            reference: reference,
            currency_rate: newRate,
            typeReport: type,
            updated_currency: selectedCurrency,
            line_items: rowsOG.map((item) => {
                let finalUP = item.unit_price * newRate;
                if (type === "SPEND") {
                    finalUP = finalUP * -1;
                }
                return {
                    _id: item._id,
                    description: item.description,
                    line_type: item.line_type,
                    quantity: item.formatted_quantity,
                    unit_price: finalUP,
                    account_code: item.account_code,
                    tax_type: item.tax_type,
                    amount: item.formatted_quantity * finalUP,
                };
            }),
        };

        await reportService.syncReport(params).then((response) => {
            console.log(response);
        });

        setShowHistSync(true);
        setIsLoading(false);
    };

    const handleConfirmUpdateXeroContact = () => {
        setContactsXero(
            contactsXero.map((item) => {
                if (item.value === "first") {
                    item.name = contactXeroUpdated;
                    item.new = true;
                }
                return item;
            })
        );
        setContact({
            value: "first",
            name: contactXeroUpdated,
        });
        setOpenModalEditContact(false);
    };

    const dialogContactButtons = (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialog}
                    color="primary"
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3,
                    }}
                >
                    {t("team.cancel")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmUpdateXeroContact}
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3,
                    }}
                    disabled={!contactXeroUpdated || contactXeroUpdated === ""}
                >
                    {t("subscription.confirm")}
                </Button>
            </Grid>
        </Grid>
    );

    const handleChangeCurrency = (event) => {
        setCurrencyChange(true);
        setSelectedCurrency(event.target.value);
    };

    return (
        <Container maxWidth="xl">
            {showHistSync ? (
                <IncomeHistSync handleGoBack={handleGoBack} />
            ) : (
                <>
                    <Stack direction="row" justifyContent="space-between" pt={5}>
                        <Button variant="outlined" onClick={handleGoBack}>
                            {t("converter.goBackConver")}
                        </Button>
                        <Button onClick={() => handleClickHelp("conversion", "report", conversionData._id)}>
                            {t("accounts.needHelp")}
                        </Button>
                    </Stack>
                    <Box
                        sx={{
                            backgroundColor: "#EFEBFB",
                            px: 3,
                            py: 2,
                            borderRadius: 4,
                            mt: 2,
                        }}
                    >
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            justifyContent={{ xs: "center", md: "space-between" }}
                            spacing={2}
                        >
                            <Box>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    pb={1}
                                    justifyContent={{ xs: "center" }}
                                >
                                    <Typography variant="h3">{t("incomes.syncToXero")}</Typography>
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 20,
                                            width: 20,
                                        }}
                                        src={XeroLogo}
                                    />
                                </Stack>
                                <Typography>
                                    <b>1</b> {t("incomes.reporTot")}
                                </Typography>
                            </Box>
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                alignItems="center"
                                spacing={{ xs: 2, md: 4 }}
                            >
                                <Button
                                    variant="outlined"
                                    disableElevation
                                    endIcon={<DownloadIcon />}
                                    sx={{ px: 4.5, py: 1.2 }}
                                    disabled={rows.find((item) => !item.account_code || !item.tax_type)}
                                    onClick={handleDownload}
                                >
                                    {t("converter.download")}
                                </Button>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    endIcon={<SyncIcon />}
                                    sx={{ px: 4.5, py: 1.2 }}
                                    disabled={
                                        integration === 3 ||
                                        rows.find((item) => !item.account_code || !item.tax_type) ||
                                        !contact ||
                                        Object.keys(contact).length === 0
                                    }
                                    onClick={handleSync}
                                >
                                    {t("converter.sync")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: "#F8F8F9",
                            px: 3,
                            py: 2,
                            borderRadius: 4,
                            mt: 2,
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5}>
                                <Typography variant="h2" color="primary" gutterBottom>
                                    {t("converter.resumeConv")}
                                </Typography>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h3">{t("dashboard.company")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Typography>{conversionData.formatted_customer_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h3">{t("accounts.createAccount1")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Typography>{conversionData.account_id.account_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h3">{t("converter.bankStatement")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Button
                                            onClick={handleOpenPrev}
                                            size="small"
                                            sx={{ fontSize: 14, p: 0 }}
                                            endIcon={<VisibilityIcon />}
                                        >
                                            {conversionData.uploaded_file}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h3">{t("payment.period")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Typography>
                                            {formatDate(conversionData.formatted_initial_date, todos.dateFormat)} -{" "}
                                            {formatDate(conversionData.formatted_end_date, todos.dateFormat)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h3">{t("incomes.bankCurrency")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Typography>{conversionData.account_id?.currency_id_xero}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h3">{t("incomes.fileCurrency")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <FormControl variant="filled" sx={{ minWidth: "40%" }} size="small">
                                                <InputLabel
                                                    id={"customized-base-select-label"}
                                                    sx={{ color: "#131f3e" }}
                                                >
                                                    {t("dashboard.currency")}
                                                </InputLabel>
                                                <Select
                                                    labelId={"customized-base-select-label"}
                                                    id={"customized-base-select"}
                                                    value={selectedCurrency}
                                                    onChange={handleChangeCurrency}
                                                    MenuProps={MenuProps}
                                                    IconComponent={(props) => {
                                                        if (props.className.includes("MuiSelect-iconOpen")) {
                                                            return (
                                                                <PlayArrowIcon
                                                                    sx={{
                                                                        position: "absolute",
                                                                        transform: "rotate(270deg)",
                                                                        color: "#131F3E",
                                                                        right: ".5rem",
                                                                        cursor: "pointer",
                                                                        zIndex: 0,
                                                                        pointerEvents: "none",
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                        return (
                                                            <PlayArrowIcon
                                                                sx={{
                                                                    position: "absolute",
                                                                    transform: "rotate(90deg)",
                                                                    color: "#131F3E",
                                                                    right: ".5rem",
                                                                    cursor: "pointer",
                                                                    zIndex: 0,
                                                                    pointerEvents: "none",
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                >
                                                    <Typography variant="h2" pl={1} gutterBottom>
                                                        {t("converter.kiiperSug")}
                                                    </Typography>
                                                    {allCurrencies.filter((item) => item.setup).length === 0 ? (
                                                        <MenuItem disabled value="">
                                                            <em>{t("converter.noCurreConf")}</em>
                                                        </MenuItem>
                                                    ) : (
                                                        allCurrencies
                                                            .filter((item) => item.setup)
                                                            .map((val) => (
                                                                <MenuItem key={val.id} value={val.id}>
                                                                    {val.id} - {val.name}
                                                                </MenuItem>
                                                            ))
                                                    )}
                                                    <Divider />
                                                    <Typography variant="h2" pl={1} gutterBottom>
                                                        {t("converter.otherCoins")}
                                                    </Typography>
                                                    {allCurrencies
                                                        .filter((item) => !item.setup)
                                                        .map((val) => (
                                                            <MenuItem key={val.id} value={val.id}>
                                                                {val.id} - {val.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            {conversionData.account_id?.currency_id_xero !== selectedCurrency && (
                                                <LightTooltip title={t("incomes.currencyNoMatch")}>
                                                    <ErrorIcon sx={{ fontSize: 18 }} htmlColor="#FFB23F" />
                                                </LightTooltip>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={1.5}></Grid>
                                    <Grid item xs={12} md={3.5} mb={1}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Autocomplete
                                                fullWidth
                                                PopperComponent={StyledPopper}
                                                options={contactsXero}
                                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                                value={contact}
                                                onChange={(event, newValue) => handleChangeContact(newValue)}
                                                renderOption={(props, option, { index }) => {
                                                    const { key, ...optionProps } = props;
                                                    if (option.value === "first") {
                                                        return (
                                                            <Box
                                                                key={key + index}
                                                                component="li"
                                                                {...optionProps}
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    width="100%"
                                                                >
                                                                    <Stack direction="column">
                                                                        <Typography fontWeight={600} color="primary">
                                                                            {t("converter.edit")} {option.name}
                                                                        </Typography>
                                                                        <Typography
                                                                            fontSize={11}
                                                                            lineHeight={1}
                                                                            color="#131f3e"
                                                                        >
                                                                            {t("converter.editContactMsg")}
                                                                        </Typography>
                                                                    </Stack>
                                                                    <IconButton
                                                                        onClick={(event) =>
                                                                            handleClickEditContact(event, option.name)
                                                                        }
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Stack>
                                                            </Box>
                                                        );
                                                    } else {
                                                        return (
                                                            <Box
                                                                key={key + index}
                                                                component="li"
                                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                {...optionProps}
                                                            >
                                                                <Typography color="#131f3e">{option.name}</Typography>
                                                            </Box>
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={
                                                            <Typography fontWeight={600} color="#131F3E">
                                                                {contact?.value === "first"
                                                                    ? t("converter.createContact")
                                                                    : t("converter.chooseContact")}
                                                            </Typography>
                                                        }
                                                        variant="filled"
                                                        size="small"
                                                    />
                                                )}
                                                isOptionEqualToValue={(option, value) => {
                                                    if (!value || Object.keys(value).length === 0) return false;
                                                    return option.value === value.value;
                                                }}
                                                popupIcon={
                                                    <PlayArrowIcon
                                                        sx={{
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                }
                                                noOptionsText={
                                                    <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                                                        <Typography
                                                            fontWeight={600}
                                                            fontSize={13}
                                                            mt={0.5}
                                                            color="#131f3e"
                                                        >
                                                            {t("converter.noContact")}
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                            {getContactIcon(getCon, contactsXeroOriginal, contactsXero, contact, t)}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={3.5} mb={1}>
                                        <CustomTextField
                                            variant="filled"
                                            name="ref"
                                            fullWidth
                                            label={t("accounts.ref")}
                                            value={reference}
                                            onChange={handleChangeRef}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3.5} mb={1}>
                                        {selectedCurrency !== conversionData.account_id?.currency_id_xero && (
                                            <CustomTextField
                                                fullWidth
                                                size="small"
                                                label={t("converter.rate")}
                                                name="rate"
                                                onChange={handleChangeRate}
                                                value={rate}
                                                variant="filled"
                                                type="number"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            sx={{ "& p": { color: "#B3B3B3" } }}
                                                        >
                                                            1 {selectedCurrency} =
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            sx={{ "& p": { color: "#B3B3B3" }, pt: 2 }}
                                                        >
                                                            {conversionData.account_id?.currency_id_xero}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            display: "none",
                                                        },
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={1.5}>
                                        <Typography variant="h3" textAlign="right">
                                            {t("converter.extracted")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3.5}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{
                                                backgroundColor:
                                                    conversionData.formatted_total_income ===
                                                    parseFloat(getTotalIncome().toFixed(2))
                                                        ? "#DBF4E5"
                                                        : "#FFF1DB",
                                                p: 1.5,
                                                borderRadius: 3,
                                            }}
                                        >
                                            <Typography variant="h3">{t("incomes.totalIncome")}</Typography>
                                            <Typography>
                                                {formatAmount(
                                                    conversionData.formatted_total_income,
                                                    todos.amountFormat
                                                )}
                                            </Typography>
                                            {conversionData.formatted_total_income ===
                                            parseFloat(getTotalIncome().toFixed(2)) ? (
                                                <CheckIcon color="success" />
                                            ) : (
                                                <ErrorIcon htmlColor="#FFB23F" />
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={3.5}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{
                                                backgroundColor:
                                                    conversionData.formatted_total_expenses ===
                                                    parseFloat(getTotalExpenses().toFixed(2))
                                                        ? "#DBF4E5"
                                                        : "#FFF1DB",
                                                p: 1.5,
                                                borderRadius: 3,
                                            }}
                                        >
                                            <Typography variant="h3">{t("incomes.totalExp")}</Typography>
                                            <Typography>
                                                {formatAmount(
                                                    conversionData.formatted_total_expenses,
                                                    todos.amountFormat
                                                )}
                                            </Typography>
                                            {conversionData.formatted_total_expenses ===
                                            parseFloat(getTotalExpenses().toFixed(2)) ? (
                                                <CheckIcon color="success" />
                                            ) : (
                                                <ErrorIcon htmlColor="#FFB23F" />
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={3.5}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{
                                                backgroundColor:
                                                    parseFloat(
                                                        (
                                                            conversionData.formatted_total_income +
                                                            conversionData.formatted_total_expenses
                                                        ).toFixed(2)
                                                    ) === parseFloat(getNetIncome().toFixed(2))
                                                        ? "#DBF4E5"
                                                        : "#FFF1DB",
                                                p: 1.5,
                                                borderRadius: 3,
                                            }}
                                        >
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography variant="h3">{t("incomes.netIncome")}</Typography>
                                                <InfoIcon fontSize="small" sx={{ color: "#4A22D4", fontSize: 15 }} />
                                            </Stack>
                                            <Typography>
                                                {formatAmount(
                                                    conversionData.formatted_total_income +
                                                        conversionData.formatted_total_expenses,
                                                    todos.amountFormat
                                                )}
                                            </Typography>
                                            {parseFloat(
                                                (
                                                    conversionData.formatted_total_income +
                                                    conversionData.formatted_total_expenses
                                                ).toFixed(2)
                                            ) === parseFloat(getNetIncome().toFixed(2)) ? (
                                                <CheckIcon color="success" />
                                            ) : (
                                                <ErrorIcon htmlColor="#FFB23F" />
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={1.5}>
                                        <Typography variant="h3" textAlign="right">
                                            {t("converter.calculated")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3.5}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{
                                                backgroundColor:
                                                    conversionData.formatted_total_income ===
                                                    parseFloat(getTotalIncome().toFixed(2))
                                                        ? "#DBF4E5"
                                                        : "#FFF1DB",
                                                p: 1.5,
                                                borderRadius: 3,
                                            }}
                                        >
                                            <Typography variant="h3">{t("incomes.totalIncome")}</Typography>
                                            <Typography>
                                                {formatAmount(getTotalIncome(), todos.amountFormat)}
                                            </Typography>
                                            {conversionData.formatted_total_income ===
                                            parseFloat(getTotalIncome().toFixed(2)) ? (
                                                <CheckIcon color="success" />
                                            ) : (
                                                <ErrorIcon htmlColor="#FFB23F" />
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={3.5}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{
                                                backgroundColor:
                                                    conversionData.formatted_total_expenses ===
                                                    parseFloat(getTotalExpenses().toFixed(2))
                                                        ? "#DBF4E5"
                                                        : "#FFF1DB",
                                                p: 1.5,
                                                borderRadius: 3,
                                            }}
                                        >
                                            <Typography variant="h3">{t("incomes.totalExp")}</Typography>
                                            <Typography>
                                                {formatAmount(getTotalExpenses(), todos.amountFormat)}
                                            </Typography>
                                            {conversionData.formatted_total_expenses ===
                                            parseFloat(getTotalExpenses().toFixed(2)) ? (
                                                <CheckIcon color="success" />
                                            ) : (
                                                <ErrorIcon htmlColor="#FFB23F" />
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={3.5}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{
                                                backgroundColor:
                                                    parseFloat(
                                                        (
                                                            conversionData.formatted_total_income +
                                                            conversionData.formatted_total_expenses
                                                        ).toFixed(2)
                                                    ) === parseFloat(getNetIncome().toFixed(2))
                                                        ? "#DBF4E5"
                                                        : "#FFF1DB",
                                                p: 1.5,
                                                borderRadius: 3,
                                            }}
                                        >
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography variant="h3">{t("incomes.netIncome")}</Typography>
                                                <InfoIcon fontSize="small" sx={{ color: "#4A22D4", fontSize: 15 }} />
                                            </Stack>
                                            <Typography>{formatAmount(getNetIncome(), todos.amountFormat)}</Typography>
                                            {parseFloat(
                                                (
                                                    conversionData.formatted_total_income +
                                                    conversionData.formatted_total_expenses
                                                ).toFixed(2)
                                            ) === parseFloat(getNetIncome().toFixed(2)) ? (
                                                <CheckIcon color="success" />
                                            ) : (
                                                <ErrorIcon htmlColor="#FFB23F" />
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <IcomeTableDet
                        conversionData={conversionData}
                        accountsXeroInc={accountsXeroInc}
                        accountsXeroExp={accountsXeroExp}
                        xeroTaxRates={xeroTaxRates}
                        setRows={setRows}
                        rows={rows}
                        setRowsOG={setRowsOG}
                        rowsOG={rowsOG}
                        rate={rate}
                        selectedCurrency={selectedCurrency}
                    />
                </>
            )}
            <FormDialog
                open={openModalEditContact}
                handleClose={handleCloseDialog}
                maxWidth="sm"
                title={
                    <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                        <InfoIcon fontSize="small" sx={{ color: "#4A22D4" }} />
                        <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">
                            {t("converter.dialogContactXero")}
                        </Typography>
                    </Stack>
                }
                content={
                    <EditContactChecks
                        extContactName={extContactName}
                        contactXeroUpdated={contactXeroUpdated}
                        setContactXeroUpdated={setContactXeroUpdated}
                    />
                }
                actions={dialogContactButtons}
                align="center"
            />
            <SimpleBackdrop open={isLoading} />
        </Container>
    );
};

export default IncomePrev;
