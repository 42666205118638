/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

/** MUI imports */
import { Typography, Grid, FormControl, InputLabel, Select, FilledInput, MenuItem } from "@mui/material";

import FiltersPopover from "../../ui/FiltersPopover";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 45 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const FiltersButtonDet = (props) => {
    const { filters, setFilters, setRows, ogData, setSelected } = props;

    const [t] = useTranslation("global");

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    /* Filtraríamos por Banco (cuenta bancaria), fecha de carga (campo desde y hasta), formato, fecha de estados de cuenta (fecha desde y hasta), usuario y estatus.*/

    const attributes = [
        { id: 1, name: t("incomes.type"), query: "type" },
        { id: 2, name: t("incomes.AccNature"), query: "sign" },
    ];

    const addFilter = () => {
        let array = [...filters.filtersArray];
        if (array.length <= 6) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                    },
                ],
            });
        }
    };

    const removeFilter = (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {
            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            let objectFinal = {};

            array.forEach((item) => {
                if (item.value && item.type) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
            });

            let newRows = [...ogData];

            if (objectFinal.type) {
                newRows = newRows.filter((elemento) => elemento.line_type === objectFinal.type);
            }
            if (objectFinal.sign) {
                if (objectFinal.sign === 1) newRows = newRows.filter((elemento) => elemento.unit_price > 0);
                if (objectFinal.sign === 2) newRows = newRows.filter((elemento) => elemento.unit_price < 0);
            }
            setRows(newRows);
            setSelected([]);
        }
    };

    const removeFirstFilter = () => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                },
            ],
        });
        setRows(ogData);
        setSelected([]);
    };

    const handleChangeType = (event, index) => {
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilter = (event, index) => {
        let aux = [...filters.filtersArray];

        aux[index].value = event.target.value;

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
        });
        setFilters({
            ...filters,
            filtersArray: aux,
        });

        let newRows = [...ogData];

        if (objectFinal.type) {
            newRows = newRows.filter((elemento) => elemento.line_type === objectFinal.type);
        }
        if (objectFinal.sign) {
            if (objectFinal.sign === 1) newRows = newRows.filter((elemento) => elemento.unit_price > 0);
            if (objectFinal.sign === 2) newRows = newRows.filter((elemento) => elemento.unit_price < 0);
        }
        setRows(newRows);
        setSelected([]);
    };

    const cleanFilters = async (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                    },
                ],
            });
            setRows(ogData);
            setSelected([]);
        }
    };

    useEffect(() => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                },
            ],
        });
    }, [setFilters]);

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={filters.filtersArray.filter((item) => item.type && (item.value || item.since || item.until)).length}
            addFilter={addFilter}
            disabled={filters.filtersArray.length === 6}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={"convComponent_" + index} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        className="ddlGeneral"
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                        defaultValue=""
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                  .filter(
                                                      (item) =>
                                                          !filters.filtersArray
                                                              .map((item) => item.type)
                                                              .includes(item.id)
                                                  )
                                                  .concat(attributes.filter((item) => item.id === value.type))
                                                  .map((item) => (
                                                      <MenuItem value={item.id} key={"attr_" + item.id}>
                                                          <Typography
                                                              variant="body1"
                                                              component="span"
                                                              align="center"
                                                              color="textPrimary"
                                                              sx={{
                                                                  fontStyle: "normal",
                                                              }}
                                                          >
                                                              {item.name}
                                                          </Typography>
                                                      </MenuItem>
                                                  ))
                                            : attributes
                                                  .filter(
                                                      (item) =>
                                                          !filters.filtersArray
                                                              .map((item) => item.type)
                                                              .includes(item.id)
                                                  )
                                                  .map((item) => (
                                                      <MenuItem value={item.id} key={"attr_" + item.id}>
                                                          <Typography
                                                              variant="body1"
                                                              component="span"
                                                              align="center"
                                                              color="textPrimary"
                                                              sx={{
                                                                  fontStyle: "normal",
                                                              }}
                                                          >
                                                              {item.name}
                                                          </Typography>
                                                      </MenuItem>
                                                  ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 1 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            <MenuItem value={1}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontStyle: "normal",
                                                    }}
                                                >
                                                    {t("payment.ingress")}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontStyle: "normal",
                                                    }}
                                                >
                                                    {t("incomes.expense")}
                                                </Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 2 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            <MenuItem value={1}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontStyle: "normal",
                                                    }}
                                                >
                                                    {t("incomes.positive")}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    align="center"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontStyle: "normal",
                                                    }}
                                                >
                                                    {t("incomes.negative")}
                                                </Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                    {filters.filtersArray.length > 1 && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFilter(index)}
                            />
                        )}
                        {filters.filtersArray.length === 1 &&
                            (value.value !== "" || value.since !== "" || value.until !== "") && (
                                <DeleteIcon
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => removeFirstFilter()}
                                />
                            )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButtonDet;
