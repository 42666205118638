/** MUI imports */
import { Box, Card, CardContent, Container, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CompaniesTable from "../company/CompaniesTable";
import AddCompany from "./companydir/AddCompany";
import { useLocation, useSearchParams } from "react-router-dom";
import companyService from "../../services/company";
import casesServices from "../../services/case";
import usercompanyService from "../../services/user_company";
import UpdateCompany from "./companydir/UpdateCompany";
import CompanyDet from "./companydir/CompanyDet";
import PaymentList from "./billing/PaymentList";
import InvoicesList from "./billing/InvoicesList";
import plansInfo from "../Js/plans";
import UpdateSubscription from "./companydir/UpdateSubscription";
import { initiateSocket, newUserResponse, subscribeToChat } from "../../services/Socket";
import ChatModal from "../ui/Chat";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 15,
    fontWeight: 300,
    mr: 3,
    py: 2.8,
    minWidth: "254px",
    backgroundColor: "#e5e0f9",
    borderRadius: "8px",
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c1b4f0",
        fontWeight: 600,
        borderRadius: "8px",
    },
    my: 0.5,
};

const SubscriptionManag = (props) => {
    const { setIsLoading, setShow, setAlertType, setTexto } = props;
    const location = useLocation();

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const [value, setValue] = useState(0);
    const [openModalNewCompany, setOpenModalNewCompany] = useState(false);
    const [reload, setReload] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [smbInfo, setSmbInfo] = useState({});
    const [showCompanyDetails, setShowCompanyDetails] = useState(false);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [valueCompany, setValueCompany] = useState(0);
    const [openCompanyDet, setOpenCompanyDet] = useState(false);
    const [allCompanies, setAllCompanies] = useState([]);
    const [payments, setPayments] = useState([]);
    const [franchiseDetails, setFranchiseDetails] = useState(null);
    const [currentFreePlan, setCurrentFreePlan] = useState(0);
    const [maxFreePlan, setMaxFreePlan] = useState(0);
    const [franchiseSubsType, setFranchiseSubsType] = useState(null);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [caseInfo, setCaseInfo] = useState({});

    let franchiseInfo = todos.allComData.find((item) => item._id === todos.franchiseID);

    const [companyInfo, setCompanyInfo] = useState({
        id: null,
        companyType: 1, // 2: SMB | 1: Firma
        companyName: "",
        country: franchiseInfo.country_name,
        systems: [],
        ecActivity: "",
        system_subscription_name: "",
    });

    const handleChangeTab = async (event, newValue) => {
        if (newValue === 2) {
            setShowCompanyDetails(false)
            setOpenCompanyDet(false)
            setOpenModalNewCompany(false)
            setIsLoading(true);
            await companyService
                .getFranchiseDetails(todos.franchiseID)
                .then((data) => {
                    setFranchiseDetails(data)
                    setValue(newValue);
                })
                .catch((error) => {
                    console.log(error);
                });
            setIsLoading(false);
        } else {
            setShowCompanyDetails(false)
            setOpenCompanyDet(false)
            setOpenModalNewCompany(false)
            setValue(newValue);
            if (newValue === 0) {
                setActiveStep(0)
            }
        }
    };

    const handleChangeTabCompany = async (event, newValue) => {
        setValueCompany(newValue);
    };

    useEffect(() => {
        (async () => {
            const company_param = searchParams.get("company_id");
            const connected_param = searchParams.get("connected");
            const connect_param = searchParams.get("connect");
            const type_param = searchParams.get("redirectType");
            const alert_param = searchParams.get("alert");
            if (company_param && type_param === "1") {
                // Redirección de Xero o Quickbooks al conectarse
                if (connected_param && connected_param === "true" && connect_param) {
                    await companyService
                        .getCompanyDetails(company_param)
                        .then((data) => {
                            setSmbInfo(data);
                            setCompanyInfo({
                                id: data._id,
                                companyType: 1,
                                companyName: data.company_name,
                                country: data.country_name,
                                systems: data.file_formats,
                                ecActivity: data.business_activity,
                                system_subscription_name: data.system_subscription_name,
                            });

                            searchParams.delete("connected");
                            searchParams.delete("connect");
                            searchParams.delete("company_id");
                            searchParams.delete("redirectType");
                            searchParams.delete("alert");
                            setSearchParams(searchParams);

                            setOpenModalNewCompany(true);
                            setAddNew(false);
                            setActiveStep(2);
                            setAlertType("success");
                            setTexto(t("dialog.connectionEstablished"));
                            setShow(true);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    // Botón de regresar
                    await companyService
                        .getCompanyDetails(company_param)
                        .then((data) => {
                            setSmbInfo(data);
                            setCompanyInfo({
                                id: data._id,
                                companyType: 1,
                                companyName: data.company_name,
                                country: data.country_name,
                                systems: data.file_formats,
                                ecActivity: data.business_activity,
                                system_subscription_name: data.system_subscription_name,
                            });

                            searchParams.delete("connected");
                            searchParams.delete("connect");
                            searchParams.delete("company_id");
                            searchParams.delete("redirectType");
                            searchParams.delete("alert");
                            setSearchParams(searchParams);
                            setOpenModalNewCompany(true);
                            setAddNew(false);

                            setActiveStep(2);
                            if (alert_param && alert_param === "1") {
                                setAlertType("error");
                                setTexto(t("dialog.alreadyConnected"));
                                setShow(true);
                            } else if (alert_param && alert_param === "2") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError1"));
                                setShow(true);
                            } else if (alert_param && alert_param === "3") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError2"));
                                setShow(true);
                            } else if (alert_param && alert_param === "4") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError3"));
                                setShow(true);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        })();
    }, [searchParams, t, setSearchParams, setActiveStep, setAlertType, setTexto, setShow]);

    useEffect(() => {
        (async () => {
            if (location.state?.tab === 0) {
                setValue(0);
                setShowCompanyDetails(false)
                setOpenCompanyDet(false)
                if (location.state?.createCompany === true) {
                    setOpenModalNewCompany(true)
                } else {
                    setOpenModalNewCompany(false)
                }

            }

            const handleOpenCompany = async (companyID) => {
                setIsLoading(true);
                await usercompanyService
                    .getBusinessDirectoryDetails({ company_id: companyID })
                    .then((data) => {
                        setCompanyDetails(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                setActiveStep(0);
                setOpenCompanyDet(true);
                setIsLoading(false);
            };

            if (location?.state !== null && "redirect_cancel" in location?.state) {
                await handleOpenCompany(location?.state?.redirect_cancel)
            }
        })();
    }, [location.state, setIsLoading]);

    const connectSocketGen = async (case_id) => {
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo)

                initiateSocket(case_id, todos.userInfo, "general", "");
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleClickHelp = async () => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: "general",
        };

        await casesServices
            .createNewCase(params)
            .then(async (response) => {
                setCaseInfo(response);
                await connectSocketGen(response._id);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    return (
        <>
            {showCompanyDetails ? (
                <>
                    <Card
                        sx={{
                            border: "none",
                            borderRadius: 0,
                            boxShadow: 0,
                            mt: 2,
                        }}
                    >
                        <CardContent sx={{ mt: 1, border: "none", borderRadius: 0 }}>
                            <Typography ml={1.8} mb={1} variant="h1" fontWeight={600}>
                                {t("dialog.settings").replace(":", "")}{" "}
                            </Typography>
                            <Tabs
                                orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                                value={valueCompany}
                                onChange={handleChangeTabCompany}
                                aria-label="Horizontal tabs"
                                sx={{
                                    p: 1,
                                    border: "none",
                                }}
                                TabIndicatorProps={{
                                    style: { display: "none" },
                                }}
                            >
                                <Tab label={t("management.companyData")} {...a11yProps(0)} sx={TabProps} />
                                <Tab label={t("subscription.integ")} {...a11yProps(1)} sx={TabProps} />
                            </Tabs>
                        </CardContent>
                    </Card>
                    <Divider />
                    <Box sx={{ background: "#f7f8f9" }}>
                        <Container>
                            <TabPanel value={valueCompany} index={0}>
                                <UpdateCompany
                                    companyDetails={companyDetails}
                                    setIsLoading={setIsLoading}
                                    setTexto={setTexto}
                                    setShow={setShow}
                                    setAlertType={setAlertType}
                                    setCompanyDetails={setCompanyDetails}
                                />
                            </TabPanel>
                            <TabPanel value={valueCompany} index={1}>
                                Tab2
                            </TabPanel>
                        </Container>
                    </Box>
                </>
            ) : (
                <>
                    <Card
                        sx={{
                            border: "none",
                            borderRadius: 0,
                            boxShadow: 0,
                            mt: 2,
                        }}
                    >
                        <CardContent sx={{ mt: 1, border: "none", borderRadius: 0 }}>
                            <Typography ml={1.8} mb={1} variant="h1" fontWeight={600}>
                                {t("management.subsManag")}{" "}
                                <Typography component="span" variant="h1" color={"primary"}>
                                    {franchiseInfo.franchise_name}
                                </Typography>
                            </Typography>
                            <Tabs
                                orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                                value={value}
                                onChange={handleChangeTab}
                                aria-label="Horizontal tabs"
                                sx={{
                                    p: 1,
                                    border: "none",
                                }}
                                TabIndicatorProps={{
                                    style: { display: "none" },
                                }}
                            >
                                <Tab label={t("miscellaneous.companyDir")} {...a11yProps(0)} sx={TabProps} />
                                <Tab label={t("payment.billing")} {...a11yProps(1)} sx={TabProps} />
                                <Tab label={t("dialog.viewFirmConfig")} {...a11yProps(2)} sx={TabProps} />
                                {/* <Tab label={t("management.subsPersonal")} {...a11yProps(3)} sx={TabProps} />
                                <Tab label={t("management.invLink")} {...a11yProps(4)} sx={TabProps} /> */}
                            </Tabs>
                        </CardContent>
                    </Card>
                    <Divider />
                    <Box sx={{ background: "#f7f8f9" }}>
                        <Container>
                            <TabPanel value={value} index={0}>
                                {!openModalNewCompany && !openCompanyDet && (
                                    <CompaniesTable
                                        setOpenModalNewCompany={setOpenModalNewCompany}
                                        setAddNew={setAddNew}
                                        reload={reload}
                                        setCompanyDetails={setCompanyDetails}
                                        setOpenCompanyDet={setOpenCompanyDet}
                                        setIsLoading={setIsLoading}
                                        setAllCompanies={setAllCompanies}
                                        allCompanies={allCompanies}
                                        setCurrentFreePlan={setCurrentFreePlan}
                                        setMaxFreePlan={setMaxFreePlan}
                                        setFranchiseSubsType={setFranchiseSubsType}
                                        setCompanyInfo={setCompanyInfo}
                                        franchiseInfo={franchiseInfo}
                                    />
                                )}
                                {openCompanyDet && (
                                    <CompanyDet
                                        setOpenCompanyDet={setOpenCompanyDet}
                                        setShowCompanyDetails={setShowCompanyDetails}
                                        companyDetails={companyDetails}
                                        allCompanies={allCompanies}
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                        setSmbInfo={setSmbInfo}
                                        smbInfo={smbInfo}
                                        setShow={setShow}
                                        setTexto={setTexto}
                                        setAlertType={setAlertType}
                                        setIsLoading={setIsLoading}
                                        setCompanyDetails={setCompanyDetails}
                                        maxFreePlan={maxFreePlan}
                                        currentFreePlan={currentFreePlan}
                                        handleClickHelp={handleClickHelp}
                                    />
                                )}
                                {openModalNewCompany && (
                                    <Box
                                        sx={{
                                            display: activeStep === 0 ? "flex" : "block",
                                        }}
                                        justifyContent="center"
                                    >
                                        <AddCompany
                                            onClose={() => setOpenModalNewCompany(false)}
                                            setReload={setReload}
                                            franchiseID={todos.franchiseID}
                                            setIsLoading={setIsLoading}
                                            setShow={setShow}
                                            setTexto={setTexto}
                                            setAlertType={setAlertType}
                                            addNew={addNew}
                                            franchiseInfo={franchiseInfo}
                                            activeStep={activeStep}
                                            setActiveStep={setActiveStep}
                                            setSmbInfo={setSmbInfo}
                                            smbInfo={smbInfo}
                                            companyInfo={companyInfo}
                                            setCompanyInfo={setCompanyInfo}
                                            allCompanies={allCompanies}
                                            plansInfo={plansInfo}
                                            currentFreePlan={currentFreePlan}
                                            maxFreePlan={maxFreePlan}
                                            franchiseSubsType={franchiseSubsType}
                                            handleClickHelp={handleClickHelp}
                                        />
                                    </Box>
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={4}>
                                        <PaymentList
                                            setIsLoading={setIsLoading}
                                            setShow={setShow}
                                            setTexto={setTexto}
                                            setAlertType={setAlertType}
                                            payments={payments}
                                            setPayments={setPayments}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <InvoicesList payments={payments} setIsLoading={setIsLoading} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <UpdateSubscription
                                    franchiseDetails={franchiseDetails}
                                    setIsLoading={setIsLoading}
                                    setTexto={setTexto}
                                    setShow={setShow}
                                    setAlertType={setAlertType}
                                />
                            </TabPanel>
                            {/* <TabPanel value={value} index={3}>
                                Tab4
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                Tab5
                            </TabPanel> */}
                        </Container>
                    </Box>
                </>
            )}
            {open && <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                users={users}
                type={4}
                franchiseName={
                    todos.allComData?.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    )?.franchise_name
                }
                chatDisabled={false}
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
                createCase={true}
            />}
        </>
    );
};

export default SubscriptionManag;
